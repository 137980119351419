var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view" },
    [
      _c("header-section", {
        attrs: {
          heading: "Artist Types",
          subheading: "Filter our beats by artist type.",
        },
      }),
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          {
            staticClass:
              "container tileGenresInstrumentsPlaylists__gridContainer",
          },
          _vm._l(_vm.artistTypes, function (artistType) {
            return _c(
              "router-link",
              {
                key: artistType.id,
                staticClass: "tilePanel tilePanel--artist",
                attrs: {
                  to: { name: "ArtistType", params: { slug: artistType.slug } },
                },
              },
              [
                _c("div", { staticClass: "tilePanel__imgContainer" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: _vm.overlayImageLink(artistType.id),
                        expression: "overlayImageLink(artistType.id)",
                      },
                    ],
                    staticClass: "tilePanel__overlay",
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: artistType.image_url,
                        expression: "artistType.image_url",
                      },
                    ],
                    staticClass: "tilePanel__img",
                  }),
                  _c("p", [_vm._v(_vm._s(artistType.name))]),
                ]),
              ]
            )
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }