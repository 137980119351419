













import { Component, Mixins } from 'vue-property-decorator'

import HeaderSection from '@/components/HeaderSection.vue'
import { fetchAllArtistTypes } from '@/api/artist-type'
import { OverlayArtistType } from '@/mixins/overlay-artist-type'

@Component({ components: { HeaderSection } })
export default class ArtistTypes extends Mixins(OverlayArtistType) {
    artistTypes: IArtistType[] = []

    async mounted() {
        this.artistTypes = await fetchAllArtistTypes(false)
        this.artistTypes.sort((a, b) => a.name.localeCompare(b.name))
    }
}
